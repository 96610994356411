import { IconButton, Stack, Tooltip } from '@mui/material';
import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';
import { Howler } from 'howler';

const lightIconColor = 'rgba(255,255,255,0.4)';

interface AudioPlayerProps {
  display: boolean,
  setDisplay: (state: boolean) => void,
}


function AudioPlayer(props: AudioPlayerProps) {

  const [volumeLevel, setVolumeLevel] = React.useState<number>(30);

  const handleChange = useCallback((event: Event, newValue: number | number[]) => {
    const volumeLevelVal = newValue as number;

    setVolumeLevel(volumeLevelVal);
    Howler.volume(volumeLevelVal / 100);
  }, []);

  const hideVolumeControls = useCallback(() => props.setDisplay(false), []);
  const showVolumeControls = useCallback(() => props.setDisplay(true), []);
  
  React.useEffect(() => {
    setTimeout(() => {
      Howler.volume(volumeLevel / 100);
    }, 100)
  }, [volumeLevel]);

  return (
      <div className="Audio-wrapper" onMouseLeave={hideVolumeControls}>
        <Box style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          {props.display === false && (
          <Tooltip title="Volume Control">
            <IconButton aria-label="delete" onClick={showVolumeControls}>
              <VolumeDown />
            </IconButton>
          </Tooltip>
          )}
          {props.display === true && (
            <div>
              <Stack 
                  spacing={2}
                  direction="row"
                  sx={{ mb: 1 }}
                  style={{width: '340px', marginTop: '4px'}}
                  alignItems="center">
                  <VolumeDown htmlColor={lightIconColor}
                />
                <Slider 
                  aria-label="Volume" 
                  size="small"
                  value={volumeLevel}  
                  onChange={handleChange}
                  color="secondary"
                  />
                <VolumeUp htmlColor={lightIconColor} />
              </Stack>
            </div>
          )}
        </Box>
      </div>
  );
}

export default AudioPlayer;
