function gcd(a: number, b: number):number {
  return b
    ? gcd(b, a % b)
    : a;
};

export enum ASPECT_RATIO {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}
  
export function getAspectRatio (): ASPECT_RATIO {
  const divisor = gcd(window.innerWidth, window.innerHeight);

  if ((window.innerWidth / divisor) - (window.innerHeight / divisor) > 0) {
    return ASPECT_RATIO.HORIZONTAL;
  } else {
    return ASPECT_RATIO.VERTICAL;
  }
};