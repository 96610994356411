import { KeepAwake } from '@capacitor-community/keep-awake';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { common } from '@mui/material/colors';
import { Breathbox } from './components/breathbox/Breathbox'
import Footer from './components/footer/Footer';
import { getAspectRatio } from './common/helpers';
import backgrounds, { Background } from './backgrounds';
import './App.css';
import { Capacitor } from '@capacitor/core';

const keepAwake = async () => {
  await KeepAwake.keepAwake();
};


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: common.white,
    },
    secondary: {
      main: common.white,
    },
  },
});

let background:Background;

function App() {
  let headerStyle = {paddingTop: ''};

  if (!background) {
    const backgroundOptions = backgrounds[getAspectRatio()];
    const bkgNum = Math.floor(Math.random() * (Math.floor(backgroundOptions.length) - Math.ceil(0)) + Math.ceil(0));
    background = backgroundOptions[bkgNum]
  }

  if (Capacitor.isNativePlatform()) {
    headerStyle.paddingTop = '50px';
    keepAwake();

    //window.screen.orientation.lock('portrait');

  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className="App" id="App-wrapper" style={{
          background: `url(${background.image})`,
          backgroundSize: 'cover',
          backgroundPosition: '30% 30%',
      }}>
        <header className="App-header" style={headerStyle}>
          <div className="App-logo"></div>
        </header>
        <main className="App-main">
          <Breathbox />
        </main>
        <Footer background={background} />
      </div>
    </ThemeProvider>
  );
}

export default App;
