import { Sound } from '../../common/AudioController';

export default [
  {
    file: 'music-1.mp3',
    key: 'music',
  },
  {
    file: 'close.mp3',
    key: 'close',
  },
  {
    file: 'bowl.mp3',
    key: 'bowl',
  },
  {
    file: 'voiceover.mp3',
    key: 'count',
    sprite: {
      '1': [46700, 800],
      '2': [48000, 800],
      '3': [49500, 800],
      '4': [51000, 800],
      '5': [52400, 800],
      '6': [54000, 800],
      '7': [55500, 800],
      '8': [57150, 800],
      '9': [58500, 800],
    }
  },
  {
    file: 'voiceover-2.mp3',
    key: 'voice2',
    sprite: {
      'Intro': [3200, 31800],
    }
  },
  {
    file: 'voiceover.mp3',
    key: 'voice',
    sprite: {
      'Intro': [4200, 37000],
      'Breathe In': [42170, 1000],
      'Hold': [45640, 600],
      'Breathe Out': [44000, 600],
      'Outtro': [61000, 8000],
    }
  },
] as Sound[]