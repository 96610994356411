import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';
import React from "react";
import { Capacitor } from '@capacitor/core';

import { Background } from "../../backgrounds";
import { IconButton, Stack, Tooltip } from '@mui/material';
import appStoreImage from '../../assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg';
import AudioPlayer from "./AudioPlayer";
import Info from "../info/Info";
import './Footer.css';

interface FooterProps {
  background: Background,
}

function Footer(props: FooterProps) {
  const [open, setOpen] = React.useState(false);
  const [display, setDisplay] = React.useState<boolean>(false);

  return (
    <main className="App-footer">
      <Stack 
        spacing={2} 
        direction="row" 
      >
      <AudioPlayer display={display} setDisplay={setDisplay} /> 
      {display === false && (
        <div style={{display: 'inline', margin: '0'}}>
          <Tooltip title="Info">
            <IconButton aria-label="More information" onClick={() => setOpen(true)}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <IconButton aria-label="settings" disabled={true}>
            <SettingsIcon />
          </IconButton>
        </div>
      )}
    </Stack>
    {Capacitor.isNativePlatform() === false && ( <div style={{marginTop: '30px'}}>
      {/* <button className="btn-ios">
        <img src={appStoreImage} alt="Download on the AppStore" />
      </button> */}
    </div>
    )}
    <div id="App-credits">
      BreathManifest BreathBox v0.1<br/>
      Photo by <a href={props.background.link}>{props.background.credit}</a> on <a href="https://unsplash.com/">Unsplash</a>
    </div>
    <Info setOpen={setOpen} open={open} />
  </main>

  );
}

export default Footer;
