import { Box, Modal} from '@mui/material';


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

interface InfoProps {
  open: boolean,
  setOpen: (state: boolean) => void
}

function Info(props: InfoProps) {

  return (
    <Modal
      className="Info-model"
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: '80%' }}>
        <h2>What is this?</h2>
        <p>
          Welcome to BreathManifest's Breathing Box, an interactive tool for box breathing.
          Box breathing is a deep breathing technique that is scientifically proven to 
          calm your mind and body. This tool will help guide your practice no matter your 
          experience level. It's completely free and will be expanded to support
          other breathing techniques. If you'd like to contribute, please <a href="#">contacts us</a>.
        </p>
        <h2>Resources</h2>
        <ul>
          <li><a target="_blank" rel="noreferrer" href="https://www.webmd.com/balance/what-is-box-breathing">What is Box Breathing</a></li>
          <li><a target="_blank" rel="noreferrer" href="https://www.health.harvard.edu/mind-and-mood/mindfulness-can-help-you-tame-fears-and-worries#:~:text=Box%20breathing&text=Hold%20your%20breath%20while%20counting,Repeat.">Mindfulness can help you tame fears and worries</a></li>
        </ul>            
      </Box>
    </Modal>
  );
}

export default Info;