import { 
  Button, 
  FormControl, 
  MenuItem, 
  Select, 
  SelectChangeEvent, 
  Stack ,
  Switch,
  FormControlLabel,
} from "@mui/material";
import React, { useCallback } from "react";

interface MenuSequenceProps {
  start(seconds: number, cycles: number): Promise<void>
}

function MenuSequence(props: MenuSequenceProps) {
  const [intervalSeconds, setIntervalSeconds] = React.useState('4');
  const [totalCycles, setTotalCycles] = React.useState('6');

  const handleIntervalSeconds = useCallback(({target: {value}}: SelectChangeEvent) => {
    setIntervalSeconds(value);
  },[]);

  const handleCycleNumber = useCallback(({target: {value}}: SelectChangeEvent) => {
    setTotalCycles(value);
  },[]);

  const start = useCallback(() => {
    props.start(+intervalSeconds, +totalCycles)
  },[intervalSeconds, totalCycles, props]);

  return (
    <div className="menu-sequence">
      <h2>Breath Box</h2>

      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <Select
          value={intervalSeconds}
          onChange={handleIntervalSeconds}
          label="Seconds per interval"
        >
          <MenuItem value={4}>4 second box</MenuItem>
          <MenuItem value={6}>6 second box</MenuItem>
          <MenuItem value={8}>8 second box</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <Select
          value={totalCycles}
          onChange={handleCycleNumber}
          label="Number of cycles"
        >
          <MenuItem value={6}>for 2 minutes</MenuItem>
          <MenuItem value={15}>for 5 minutes</MenuItem>
          <MenuItem value={30}>for 10 minutes</MenuItem>
        </Select>
      </FormControl>
      <Stack spacing={2} direction="row" style={{justifyContent: 'center'}}>
        <Button
          style={{width: '66%'}}
          onClick={start}
          color="primary" 
          variant="outlined"
          aria-label="Start 2 minute box">
            Start
        </Button>
      </Stack>
      <div>
        <FormControlLabel
          style={{paddingTop: '30px'}}
          control={<Switch defaultChecked />}
          label="Skip Intro"
        />
      </div>
    </div>
  );
}

export default MenuSequence;
