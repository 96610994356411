export enum TimelineRules {
  CYCLE='CYCLE',
  INTERVAL='INTERVAL',
  SECOND='SECOND',
}

type ActionCallbackParams = {
  second: number, 
  interval: number, 
  cycle: number,
  intervalSeconds: number,
}

type ActionCallback = {(refObject: ActionCallbackParams): void;}

export type EventActions = {
  [key: string]: ActionCallback[];
}

export class Timeline {
  private actions: EventActions = {};
  private secondsInInterval: number;
  private intervalsInCycle: number;
  private totalSeconds: number = 0;
  private second: number = 0;
  private intervalSeconds: number = 0;
  private interval: number = 1;
  private cycle: number = 1;
  private actionKey: string = '';
  private event: TimelineRules = TimelineRules.CYCLE;
  private value: string|number|boolean = false;
  private totalCycles: number;
  private skipIntro: boolean = false;
  public active: boolean = false;

  constructor(
    secondsInInterval: number,
    intervalsInCycles: number,
    totalCycles: number,
  ) {
    this.secondsInInterval = secondsInInterval;
    this.intervalsInCycle = intervalsInCycles;
    this.totalCycles = totalCycles;
    this.totalSeconds = this.secondsInInterval * this.intervalsInCycle * this.totalCycles;
    this.active = true;
  }

  delay(time: number) {
    return new Promise(resolve => {
      setTimeout(() => { resolve('') }, time);
    })  
  }

  at(
    event: TimelineRules.CYCLE | TimelineRules.INTERVAL | TimelineRules.SECOND,
    value?: string|number, 
  ) {
    this.event = event;
    this.value = value || '';

    return this;
  }

  private getActionKey(append: string|null) {
    if (this.value && this.event !== TimelineRules.SECOND) {

      if (append === null) {
        append = this.event === TimelineRules.CYCLE ? 
          `${this.intervalsInCycle}_${this.secondsInInterval - 1}`
        : 
          `${this.secondsInInterval}_0`;
      }
      
      this.actionKey = `${this.event}_${this.value}_${append}`;
    } else {
      if (append) {
        this.actionKey = `${this.event}_${append}`;
      } else {
        this.actionKey = this.event;
      }
    }
  }

  start(fnc: ActionCallback) {
    this.getActionKey('0_0');
    this.value = '';
    
    this.storeAction(fnc);
  }

  end(fnc: ActionCallback) {
    this.getActionKey(null);
    this.value = '';
    this.storeAction(fnc);
  }

  private storeAction(fnc: ActionCallback) {

    if (!this.actions[this.actionKey]) {
      this.actions[this.actionKey] = [];
    }

    this.actions[this.actionKey].push((refObject: ActionCallbackParams) => {
      fnc(refObject);
    })
  }

  private async execute(actions: Function[]) {
    actions.forEach((fnc) => {
      fnc({
        second: this.second,
        interval: this.interval,
        cycle: this.cycle - 1,
        intervalSeconds: this.intervalSeconds,
      });
    })
  }

  private async evaluate() {
    
    const _this = this;

    if (this.totalSeconds === this.second || !this.active) {
      return;
    }

    [TimelineRules.CYCLE, TimelineRules.INTERVAL].forEach(async (rule) => {
        const propertyValue = rule === TimelineRules.CYCLE ? this.cycle : this.interval;
        const append = rule === TimelineRules.CYCLE ? 
          `${this.interval}_${this.intervalSeconds}`
        : 
          `${this.intervalSeconds}_0`;

        const actionKey = `${rule}_${propertyValue}_${append}`;

        if (this.actions[actionKey]) {
          await this.execute(_this.actions[actionKey]);
        }
    });

    await this.delay(1000);

    this.second += 1;
    this.intervalSeconds +=1;

    if (this.actions[TimelineRules.SECOND]) {
      await this.execute(this.actions[TimelineRules.SECOND]);
    }

    // Start of interval
    if (this.intervalSeconds === 1) {
      if (this.actions[TimelineRules.INTERVAL + '_0_0']) {
        await this.execute(this.actions[TimelineRules.INTERVAL + '_0_0']);
      }
    }

    // End of interval
    if (this.intervalSeconds === this.secondsInInterval) {
      this.intervalSeconds = 0;

      // Check if this is also the end of the cycle
      if (this.interval === this.intervalsInCycle) {
        this.cycle += 1;
        this.interval = 1;

        if (this.actions[TimelineRules.CYCLE]) {
          await this.execute(this.actions[TimelineRules.CYCLE]);
        }

        await this.delay(1000);
      } else {
        this.interval +=1;
      }

      if (this.actions[TimelineRules.INTERVAL]) {
        await this.execute(this.actions[TimelineRules.INTERVAL]);
      }
    }

    this.evaluate();
  }

  async run() {
    await this.evaluate();
  }

  async isActive() {
    return this.active;
  }

  setSkipIntro(state = true) {
    this.skipIntro = state;
  }

  getSkipIntro() {
    return this.skipIntro;
  }


  async stop() {
    this.active = false;

    await this.delay(200);
  }
}