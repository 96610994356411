import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

declare global {
  interface Window { mobile: boolean; }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function initApp() {
  root.render(
    // <React.StrictMode>
      <App />
    // </React.StrictMode>
  ); 
}

// The usingCordova function returns true if cordova is in the window
if (window.screen.width < 600) {
  setTimeout(() => {
    // Init app on device ready
    initApp();
  }, 500);

} else {
  // Init app immediately
  initApp();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
