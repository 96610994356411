import anime from 'animejs';
import React, { useCallback } from 'react';
import Close from '@mui/icons-material/Close';
import { Button, IconButton } from '@mui/material';
import { Capacitor } from '@capacitor/core';
import { Timeline, TimelineRules } from '../../common/Timeline';
import { animateLines, animateLung, stopAll } from './animate';
import MenuSequence from './MenuSequence';
import SuccessSequence from './SuccessSequence';
import { AudioController } from '../../common/AudioController';
import audioConfig from './audioConfig';
import './Breathbox.css';

export const LUNG_STATES = ['', 'Breathe In', 'Hold', 'Breathe Out', 'Hold'];
const NUM_INTERVALS_IN_CYCLE = 4;
enum DISPLAY_STATES {
  MENU_SEQUENCE = 'MENU_SEQUENCE',
  BREATHING_BOX = 'BREATHING_BOX',
  SUCCESS_SEQUENCE = 'SUCCESS_SEQUENCE',
};

export function Breathbox() {
  const animation: anime.AnimeInstance[] = [];
  const animations = React.useRef(animation);
  const [lungText, setlungText] = React.useState('');
  const [interval, setInterval] = React.useState(99);
  const [intervalSeconds, setIntervalSeconds] = React.useState(0);
  const [display, setDisplay] = React.useState(DISPLAY_STATES.MENU_SEQUENCE);
  const timeline = React.useRef(new Timeline(1, 4, 5));
  const audioController = React.useRef(new AudioController([]));

  // These variables are being set, but not used.
  // They may be useful as the functionality expands
  const [cycles, setCycles] = React.useState(0);
  const [totalCycles, setTotalCycles] = React.useState(6);
  const [secondsInInterval, setSecondsInInterval] = React.useState(4);
  const [seconds, setSeconds] = React.useState(0);

  const size = (window.screen.width < 400 || Capacitor.isNativePlatform()) ? window.screen.width - (window.screen.width * .2) : 400;

  const wrapperStyle = {
    width: `${size}px`,
    height: `${size}px`,
  };

  // This is a temporary fix for animations not working correctly.
  window.addEventListener('blur', () => stopBreatheBox());

  async function countDown(): Promise<boolean> {
    let count = 33;
    let skip = false;

    setIntervalSeconds(count);
    setlungText('Start');

    await timeline.current.delay(500);

    audioController.current.play('voice2.Intro');

    while (count > 0) {

      if (!await timeline.current.isActive()) {
        return false;
      }

      if (timeline.current.getSkipIntro() && !skip) {
        count = 3;
        skip = true;
        audioController.current.fade('voice2', 1000);
      }

      if (count === 4) {
        timeline.current.setSkipIntro();
        skip = true;
      }

      setIntervalSeconds(count);

      await timeline.current.delay(1000);
      count--;
    }

    return true;
  }

  const waitingStateAnimation = async () => {
    animations.current = await animateLines(.5, 4, 1.8);
  }

  const stopBreatheBox = useCallback(async (
    state: DISPLAY_STATES = DISPLAY_STATES.MENU_SEQUENCE
  ) => {
    if (state !== DISPLAY_STATES.SUCCESS_SEQUENCE) {
      audioController.current.fade();
    }

    setCycles(0);
    setInterval(99);

    await timeline.current.stop();
    await stopBoxAnimation();
    await waitingStateAnimation();
    setDisplay(state);
  },[]);

  const startBreatheBox = useCallback(async (
    secondsInInterval: number, 
    totalCycles: number
  ): Promise<void> => {
    setDisplay(DISPLAY_STATES.BREATHING_BOX);
    setCycles(0);

    timeline.current = new Timeline(
      secondsInInterval,
      NUM_INTERVALS_IN_CYCLE,
      totalCycles,
    );

    // Start countdown
    if (await countDown() === false) {
      // If the breathbox is cancelled, exit the start process
      return;
    }

    // Start animations
    animations.current = await animateLines(secondsInInterval,NUM_INTERVALS_IN_CYCLE);
    animations.current.push(animateLung('.box-lung', '36%', '80%', secondsInInterval));
    animations.current.push(animateLung('.box-lung-decoration-1', '0%', '90%', secondsInInterval, true));
    animations.current.push(animateLung('.box-lung-decoration-2', '0%', '70%', secondsInInterval, true, 1000));

    // What to do at the start of each cycle
    timeline.current.at(TimelineRules.INTERVAL).start(({interval}) => {
      setlungText(LUNG_STATES[interval]);
      setInterval(interval);
    });

    // What to do at the end of each cycle
    timeline.current.at(TimelineRules.INTERVAL).end(({interval}) => {
      setlungText(LUNG_STATES[interval]);
      //audioController.current.play(`count.5`);
    });

    // What to do at the end of each cycle
    timeline.current.at(TimelineRules.CYCLE).end(async ({cycle}) => {
      setCycles(cycle);
      if (cycle === totalCycles) {
        stopBreatheBox(DISPLAY_STATES.SUCCESS_SEQUENCE);

        audioController.current.stop('voice');
        audioController.current.play('bowl');
        await timeline.current.delay(500);

        audioController.current.play('voice.Outtro');

        await timeline.current.delay(15000);

        audioController.current.fade('voice', 4000);
      }
    });

    // What to do at each second
    timeline.current.at(TimelineRules.SECOND).end(async ({intervalSeconds, second, interval}) => {
      if (intervalSeconds !== 1) {
        audioController.current.play(`count.${intervalSeconds}`);
      } 
      
      if (intervalSeconds === 1) {
        //await timeline.current.delay(800);
        const intervalAdj=interval;
        audioController.current.play(`voice.${LUNG_STATES[intervalAdj]}`);
      }

      setSeconds(second);
      setIntervalSeconds(intervalSeconds)}
    );

    // Start timeline
    timeline.current.run();
  }, [stopBreatheBox]);

  const skipIntro = useCallback(() => {
    timeline.current.setSkipIntro();
  },[]);

  const start = useCallback(async (
    secondsInInterval: number, 
    totalCycles: number,
  ) => {
    if (animations.current.length) {
      await stopBoxAnimation();
    }

    setDisplay(DISPLAY_STATES.BREATHING_BOX);
    setlungText('Loading');

    if (!await audioController.current.isLoaded()) {
      audioController.current = new AudioController(audioConfig);
      await audioController.current.waitUntilLoaded();
    }

    await audioController.current.play('music');
    await timeline.current.stop();
    
    setSecondsInInterval(secondsInInterval);
    setTotalCycles(totalCycles);
    startBreatheBox(secondsInInterval, totalCycles);
  }, [startBreatheBox]);

  React.useEffect(() => {
    waitingStateAnimation();
  }, []);

  // const progress = cycles / totalCycles * 100;
  // const progrssBuffer = (seconds / (totalCycles * 4 * secondsInInterval)) * 100;

  const sessionCloseHandler = useCallback(() => {
    audioController.current.play('close');
    stopBreatheBox();
  }, [stopBreatheBox])

  async function stopBoxAnimation() {
    await stopAll(animations.current);
    animations.current = [];
  }

  function displayState() {
    switch (display) {
      case DISPLAY_STATES.BREATHING_BOX: 
        return 'state-breathingbox';
      case DISPLAY_STATES.SUCCESS_SEQUENCE: 
        return 'state-success';
      default: 
        return 'state-menu';
    }
  }

  return (
    <div className={displayState() + ' current-interval-' + interval}>
      <div className="Breathbox-wrapper" style={wrapperStyle}>
        <IconButton
          id="breathingbox-close"
          onClick={sessionCloseHandler}
          aria-label="Close active breathing box session"
          >
            <Close />
        </IconButton>
        {Boolean(timeline.current.getSkipIntro()) === false && (
          <Button
            id="breathingbox-skip-intro"
            variant='outlined'
            onClick={skipIntro}
          >
            Skip intro
          </Button>
        )}
        <div className="box-lung"></div>
        <div className="box-lung-decoration-1"></div>
        <div className="box-lung-decoration-2"></div>
        <div className="text"> 
          <div className="breathing-box-text">
            <strong>{lungText}</strong>
            <div>
              {intervalSeconds}
            </div>
          </div>
          <SuccessSequence start={start} />
          <MenuSequence start={start} />
        </div>
        <div className="line line1" />
        <div className="line line2" />
        <div className="line line3" />
        <div className="line line4" />
        <div className="box-text box-right">Breathe In</div>
        <div className="box-text box-top">Hold</div>
        <div className="box-text box-left">Breathe Out</div>
        <div className="box-text box-bottom">Hold</div>
      </div>
    </div>
  );
}

export default Breathbox;
