import { Button } from "@mui/material";
import Sparkle from 'react-sparkle'

interface SuccessSequenceProps {
  start(seconds: number, cycles: number): Promise<void>
}

function SuccessSequence(props: SuccessSequenceProps) {
  return (
    <div className="success-sequence">
      <h2>You did it!</h2>
      <h3>Breathe normally. This practice is complete.</h3>

      <Button  variant="outlined">Share this tool with a friend</Button>
      <Sparkle 
        minSize={5}
        maxSize={8}
        overflowPx={400}
        fadeOutSpeed={20}
      />
    </div>
  );
}

export default SuccessSequence;
