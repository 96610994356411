import { Howl, SoundSpriteDefinitions } from 'howler';

export type Sound = {
  file: string;
  key: string;
  sprite?: SoundSpriteDefinitions; 
}

enum State {
  PLAYING = 'PLAYING',
  STOPPED = 'STOPPED',
  PAUSED = 'PAUSED',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

interface AvailableSounds {
   [key: string]: Howl
}

interface SoundStates {
  [key: string]: State
}

export class AudioController {

  private states: SoundStates = {};
  private availableSounds: AvailableSounds = {};
  private totalSounds = 0;

  constructor(
    sounds: Sound[]
  ) {
    this.totalSounds = sounds.length;
    this.loadAudioFiles(sounds);
  }

  private async loadAudioFiles (sounds: Sound[]) {
    for (const sound of sounds) {
      const importedSound = await import(`../assets/audio/${sound.file}`);

      const soundRef = new Howl({
        src: importedSound.default,
        html5: false,
        format: ['mp3'],
        sprite: sound.sprite
      });

      this.availableSounds[sound.key] = soundRef;
      this.states[sound.key] = State.LOADING;
    }
  }

  async isLoaded() {
    return Object.keys(this.availableSounds).length > 0;
  }

  async waitUntilLoaded() {
    if (Object.keys(this.availableSounds).length === this.totalSounds) {
      for (const sound in this.availableSounds) {
        if (this.availableSounds[sound].state() !== 'loaded') {
          new Promise(resolve => {
            setTimeout(async () => { 
              await this.waitUntilLoaded();
              resolve('');
            }, 100);
          });
          break;
        } else {
          this.states[sound] = State.LOADED;
        }
      }

      return true;
    } else {
      return new Promise(resolve => {
        setTimeout(async () => { 
          await this.waitUntilLoaded();
          resolve('');
        }, 100);
      })
    }
  }

  async play(id: string, volume = 1) {

    let key = id;
    let sprite;

    if (id.indexOf('.') !== -1) {
      [key, sprite] = id.split('.');
    }

    if (this.availableSounds[key]) {
      this.availableSounds[key].volume(volume);
      this.availableSounds[key].stop();
      this.availableSounds[key].play(sprite);
      this.states[key] = State.PLAYING;
    } else {
      console.log('AUDIO REF NOT FOUND: ' + key);
    }
  }

  stop(id: string) {
    if (this.availableSounds[id]) {
      this.availableSounds[id].stop();
      this.states[id] = State.STOPPED;
    } else {
      console.log('AUDIO REF NOT FOUND');
    }
  }

  private async fadeOut(id: string, duration: number): Promise<void> {

    if (!this.availableSounds[id]) {
      console.log('AUDIO REF NOT FOUND: ' + id);
      return;
    }

    if (this.states[id] !== State.STOPPED) {
      this.availableSounds[id].fade(1, 0, duration);
      this.states[id] = State.STOPPED;
    }
  }

  async fade(id?: string, duration = 3000) {
    if (id) {
      this.fadeOut(id, duration);
    } else {
      for (let key in this.availableSounds) {
        await this.fadeOut(key, duration);
      }
    }
  }
}