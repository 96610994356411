import anime from 'animejs';

function animateEachLine(
  time: number,
  interval: number,
  delay: number,
):anime.AnimeInstance {
  return anime({
    targets: `.line${interval}`,
    [interval % 2 === 0 ? 'width' : 'height']: {
      value: '99%',
      duration: time,
    },
    background: {
      value: 'rgba(8,56,81, .4)',
      delay: time * interval,
      easing: 'easeInQuad',
    },
    opacity: {
      value: 0,
      duration: 1000,
      delay: delay * 1000,
    },
    delay: (interval - 1) * time,
    easing: 'easeInQuad',
    direction: 'normal',
    loop: true,
  })
}

export async function animateLines(
  seconds: number,
  intervals: number = 4,
  delay: number = 0,
): Promise<anime.AnimeInstance[]> {
  const time = seconds * 1000;
  const lines = [];

  if (!delay) {
    delay = seconds * intervals;
  }

  for (let i =1; i <= 4; i++) {
    lines.push(animateEachLine(time, i, delay));
  }

  return lines
}
  
export function animateLung(
  target: string, 
  min: string, 
  max: string, 
  seconds: number, 
  fadeout: boolean=false,
  delay: number=0,
): anime.AnimeInstance {
  const lung = anime.timeline({
      targets: target,
      direction: 'normal',
      loop: true,
  });

  if (delay) {
    lung.add({
      endDelay: delay,
    });
  }

  if (fadeout) {
    lung.add({
      width: max,
      height: max,
      duration: 3000,
      easing: 'easeInQuad',
      opacity: {
        value: .6,
        duration: 2000
      },
    });

    lung.add({
      width: min,
      height: min,
      duration: 3000,
      easing: 'easeInQuad',
      opacity: 0,
      endDelay: 1000,
    });
  } else {
    lung.add({
      width: max,
      height: max,
      duration: seconds * 1000,
      easing: 'easeInQuad',
      opacity: .6,
      endDelay: (seconds * 1000) + 1000,
    });

    lung.add({
      width: min,
      height: min,
      duration: seconds * 1000,
      easing: 'easeInQuad',
      opacity: 1,
      endDelay: seconds * 1000,
    });
  }

  return lung;
}

export async function stopAll (
  animations: Array<anime.AnimeInstance>,
): Promise<void> {
  if (animations.length) {
    for (const animation of animations) {
      animation.restart();
      animation.pause();
    }
  }
}