import background1 from './assets/images/background1.jpg';
import background2 from './assets/images/background2.jpg';
import background3 from './assets/images/background3.jpg';
import background4 from './assets/images/background4.jpg';
import background5 from './assets/images/background5.jpg';
import background6 from './assets/images/background6.jpg';
import background7 from './assets/images/background7.jpg';

import { ASPECT_RATIO } from './common/helpers';

export type Background = {
  link: string,
  credit: string;
  image: string,
}

const backgrounds = {
  [ASPECT_RATIO.VERTICAL]: [
    {
      link: 'https://unsplash.com/@aronvisuals',
      credit: '@Aronvisuals',
      image: background1,
    },
    {
      link: 'https://unsplash.com/@aleskrivec',
      credit: '@Ales Krivec',
      image: background2,
    },
    {
      link: 'https://unsplash.com/@sleepless_explorer',
      credit: '@sleepless_explorer',
      image: background3,
    },
    {
      link: 'https://unsplash.com/',
      credit: '@Ashim D’Silva',
      image: background5,
    },
    {
      link: 'https://unsplash.com/@sgoodgame',
      credit: '@Sam Goodgame',
      image: background7,
    },
  ] as Background[],
  [ASPECT_RATIO.HORIZONTAL]: [
    {
      link: 'https://unsplash.com/@aleskrivec',
      credit: '@Ales Krivec',
      image: background2,
    },
    {
      link: 'https://unsplash.com/@v2osk',
      credit: '@v2osk',
      image: background3,
    },
    {
      link: 'https://unsplash.com/',
      credit: '@Vincentiu Solomon',
      image: background4,
    },
    {
      link: 'https://unsplash.com/',
      credit: '@Ashim D’Silva',
      image: background5,
    },
    {
      link: 'https://unsplash.com/',
      credit: '@John Fowler',
      image: background6,
    },
    {
      link: 'https://unsplash.com/@sgoodgame',
      credit: '@Sam Goodgame',
      image: background7,
    },
  ] as Background[],
};

export default backgrounds;